import * as React from 'react';
import { Div, Hr } from './Elements';
import { Color } from './Elements/types';

type DivProps = Omit<React.ComponentProps<typeof Div>, 'color'> & {
  color?: Color;
};

export const Divider: React.FC<DivProps> = ({
  children,
  color = 'gray2',
  paddingVertical = 2,
  ...props
}) => {
  return (
    <Div className="divider" paddingVertical={paddingVertical} width="100%" {...props}>
      <Hr color={color} border="top" marginVertical={0} />
    </Div>
  );
};
