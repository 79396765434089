import React from 'react';
import {
  WidthPercentIndex,
  DisplayIndex,
  BreakpointIndex,
  SpaceIndex,
  WidthIndex,
  FlexAlignmentIndex,
  FontIndex,
  FlexContentIndex,
  FlexOrderIndex,
  LetterSpacingIndex,
  LineHeightIndex,
  FontWeightIndex,
  Color,
  Breakpoints,
  Opacity,
  SpacingStyleProps,
  ShadowScale,
  ZIndexScale,
  WordBreakValues,
  BorderWidthScale,
} from './types';

type StyleProp = keyof StyleSystemProps;

function isStyleProp(key: string): key is StyleProp {
  return key in propClassNameMap;
}

function isFullBreakpointTuple(value: any): value is [any, any, any, any] {
  return (
    Array.isArray(value) &&
    value[0] !== undefined &&
    value[1] !== undefined &&
    value[2] !== undefined &&
    value[3] !== undefined
  );
}

function isPartialBreakpointTuple(value: any): value is [any, any] {
  return (
    Array.isArray(value) &&
    !isFullBreakpointTuple(value) &&
    value[0] !== undefined &&
    value[1] !== undefined
  );
}

function isWidthPercentScale(value: any): value is WidthPercentIndex {
  return typeof value === 'string' && value.endsWith('%');
}

function isDisplayIndex(value: any): value is DisplayIndex {
  return value in displayValueMap;
}

function shouldHyphenate(key: keyof StyleSystemProps, value: any) {
  if (key === 'width' && (isWidthPercentScale(value) || value === 'auto')) {
    return true;
  }

  if (key === 'maxWidth' && (value === '100%' || value === 'none')) {
    return true;
  }

  return false;
}

const breakpointMap: { [Breakpoint in BreakpointIndex]: string } = {
  0: '',
  1: 'ns',
  2: 'm',
  3: 'l',
};

const displayValueMap: { [K in DisplayIndex]: string } = {
  none: 'n',
  flex: '',
  'inline-flex': '',
  inline: 'i',
  block: 'b',
  'inline-block': 'ib',
  'inline-table': 'it',
  table: 't',
  'table-cell': 'tc',
  'table-row': 't-row',
  'table-row-group': 't-row-group',
  'table-column': 't-column',
  'table-column-group': 't-column-group',
};

const textAlignValueMap: { [K in StyleSystemPropsNoBreakPoints['textAlign']]: string } = {
  left: 'l',
  right: 'r',
  center: 'c',
  justify: 'j',
};

const textTransformValueMap: { [K in StyleSystemPropsNoBreakPoints['textTransform']]: string } = {
  capitalize: 'c',
  lowercase: 'l',
  uppercase: 'u',
  none: 'n',
};

const borderValueMap: { [K in StyleSystemPropsNoBreakPoints['border']]: string } = {
  all: 'a',
  none: 'n',
  top: 't',
  right: 'r',
  bottom: 'b',
  left: 'l',
};

const heightPercentageMap: {
  [K in StyleSystemPropsNoBreakPoints['heightPercentage']]: number;
} = {
  25: 25,
  50: 50,
  75: 75,
  100: 100,
};

type GetClassNameOptions<Key extends keyof StyleSystemProps> = {
  key: Key;
  value: StyleSystemProps[Key] | undefined;
  breakpoint: BreakpointIndex;
};

function getClassName<Key extends keyof StyleSystemProps>({
  key,
  value,
  breakpoint,
}: GetClassNameOptions<Key>) {
  if (key === 'center') return 'center';

  let className = '';

  if (key === 'display' && (value === 'flex' || value === 'inline-flex')) {
    className = value as any;
  } else {
    className =
      propClassNameMap[key] + (shouldHyphenate(key, value) ? '-' : '') + getValue(key, value);
  }

  return className + (breakpoint > 0 ? `-${breakpointMap[breakpoint]}` : '');
}

function getValue(key: keyof StyleSystemProps, value: any) {
  if ((key === 'width' || key === 'maxWidth') && isWidthPercentScale(value))
    return value.slice(0, -1);

  if (key === 'border') return borderValueMap[value as StyleSystemPropsNoBreakPoints['border']];

  if (key === 'display' && isDisplayIndex(value)) {
    return displayValueMap[value];
  }

  if (isMargin(key) && isAuto(value)) {
    return `-${value}`;
  }

  if (isOpacity(key, value)) {
    if (value === 0.05) return '05';
    if (value === 0.025) return '025';
    return (value * 100).toString();
  }

  if (key === 'heightPercentage') {
    return heightPercentageMap[value as StyleSystemPropsNoBreakPoints['heightPercentage']];
  }
  if (key === 'textAlign' && isTextAlign(value)) {
    return textAlignValueMap[value];
  }

  if (key === 'textTransform' && isTextTransform(value)) {
    return textTransformValueMap[value];
  }

  if (isOutline(key, value)) {
    if (value === true) return '';
    if (value === false) return '-';
    if (value === 'transparent') return '-transparent';
  }

  return value;
}

const marginKeys = new Set<SpacingStyleProps>([
  'marginTop',
  'mt',
  'marginBottom',
  'mb',
  'marginRight',
  'mr',
  'marginLeft',
  'ml',
  'marginHorizontal',
  'mh',
  'marginVertical',
  'mv',
]);

function isTextAlign(value: any): value is StyleSystemPropsNoBreakPoints['textAlign'] {
  return value in textAlignValueMap;
}

function isTextTransform(value: any): value is StyleSystemPropsNoBreakPoints['textTransform'] {
  return value in textTransformValueMap;
}

function isAuto(value: any): value is 'auto' {
  return value === 'auto';
}

function isMargin(key: keyof StyleSystemProps): key is SpacingStyleProps {
  return marginKeys.has(key as any);
}

function isOpacity(key: keyof StyleSystemProps, value: any): value is Opacity {
  return key === 'opacity';
}

function isOutline(
  key: keyof StyleSystemProps,
  value: any
): value is StyleSystemPropsNoBreakPoints['outline'] {
  return key === 'outline';
}

// Maps prop names to their classNames
const propClassNameMap: { [K in StyleProp]: string } = {
  // dimensional
  display: 'd',
  width: 'w',
  height: 'h',
  heightPercentage: 'h-',
  maxWidth: 'mw',
  // margin
  margin: 'ma',
  marginTop: 'mt',
  mt: 'mt',
  marginBottom: 'mb',
  mb: 'mb',
  marginRight: 'mr',
  mr: 'mr',
  marginLeft: 'ml',
  ml: 'ml',
  marginHorizontal: 'mh',
  mh: 'mh',
  marginVertical: 'mv',
  mv: 'mv',
  center: 'center',
  position: '',
  zIndex: 'z-',
  rotate: 'rotate-',
  // padding
  padding: 'pa',
  paddingTop: 'pt',
  pt: 'pt',
  paddingBottom: 'pb',
  pb: 'pb',
  paddingRight: 'pr',
  pr: 'pr',
  paddingLeft: 'pl',
  pl: 'pl',
  paddingHorizontal: 'ph',
  ph: 'ph',
  paddingVertical: 'pv',
  pv: 'pv',
  // flex
  flex: 'flex-',
  flexDirection: 'flex-',
  flexWrap: 'flex-',
  flexShrink: 'flex-shrink-',
  alignItems: 'items-',
  alignSelf: 'self-',
  justifyContent: 'justify-',
  alignContent: 'content-',
  order: 'order-',
  // typography
  fontSize: 'f',
  fontWeight: 'fw',
  lineHeight: 'lh-',
  letterSpacing: 'ls',
  textAlign: 't',
  textTransform: 'tt',
  // theme
  color: '',
  backgroundColor: 'bg-',
  opacity: 'o-',
  fill: 'fill-',
  shadow: 'shadow-',
  // borders
  border: 'b',
  borderWidth: 'bw',
  borderColor: 'b--',
  outline: 'outline',
  wordBreak: 'wb-',
  outlineStyle: 'outline--',
  outlineWidth: 'outline-width--',
  outlineColor: 'outline--',
  outlineInnerOffset: 'outline-inner_offset--',
};

type StyleSystemPropsNoBreakPoints = {
  [K in SpacingStyleProps]: SpaceIndex | 'auto';
} & {
  // dimensional
  width: WidthIndex | WidthPercentIndex;
  height: WidthIndex;
  heightPercentage: 25 | 50 | 75 | 100;
  maxWidth: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | '100%' | 'none';
  display: DisplayIndex;
  center: true;
  position: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';
  zIndex: ZIndexScale;
  rotate: 0 | 45 | 90 | 135 | 180 | 225 | 270 | 315;
  // flex
  flex: 'none' | 'auto';
  flexDirection: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  alignItems: FlexAlignmentIndex;
  alignSelf: FlexAlignmentIndex;
  fontSize: FontIndex;
  flexWrap: 'wrap' | 'nowrap' | 'wrap-reverse';
  flexShrink: 0 | 1;
  justifyContent: FlexContentIndex;
  alignContent: FlexContentIndex;
  order: FlexOrderIndex;
  // typography
  letterSpacing: LetterSpacingIndex;
  lineHeight: LineHeightIndex;
  fontWeight: FontWeightIndex;
  textAlign: 'left' | 'right' | 'center' | 'justify';
  textTransform: 'capitalize' | 'lowercase' | 'uppercase' | 'none';
  // theme
  color: Color;
  backgroundColor: Color;
  opacity: Opacity;
  fill: Color;
  // border
  border: 'all' | 'top' | 'right' | 'bottom' | 'left' | 'none';
  borderColor: Color;
  borderWidth: BorderWidthScale;
  shadow: ShadowScale;
  outline: true | false | 'transparent';
  wordBreak: WordBreakValues;
  outlineStyle: 'solid' | 'none';
  outlineWidth: 0 | 1 | 2;
  outlineColor: Color;
  outlineInnerOffset: 0 | 1 | 2;
};

export type StyleSystemProps = {
  [K in keyof StyleSystemPropsNoBreakPoints]:
    | StyleSystemPropsNoBreakPoints[K]
    | Breakpoints<StyleSystemPropsNoBreakPoints[K]>;
};

export type PseudoProps = {
  /**
   * The styles to apply when the element is in a focused state
   */
  focused?: Partial<StyleSystemProps>;
  /**
   * The styles to apply when the element is in a hovered state
   */
  hovered?: Partial<StyleSystemProps>;
};

type StyledElement<Tag extends keyof React.ReactHTML> = React.FC<
  Partial<StyleSystemProps> & Omit<React.HTMLProps<Tag>, keyof StyleSystemProps> & PseudoProps
>;

/**
 * Makes a styled component
 * @param tag HTML tag of the element to make
 */
export function makeElement<Tag extends keyof React.ReactHTML>(
  tag: Tag,
  baseClassName?: string
): StyledElement<Tag> {
  const Element: StyledElement<Tag> = props => {
    const [isFocused, setIsFocused] = React.useState(false);
    const [isHovered, setIsHovered] = React.useState(false);
    const pseudoSelectorProps: Partial<StyleSystemProps> = {};

    if (isFocused && props.focused) {
      for (let key in props.focused) {
        (pseudoSelectorProps as any)[key] = (props.focused as any)[key];
      }
    }

    if (isHovered) {
      for (let key in props.hovered) {
        (pseudoSelectorProps as any)[key] = (props.hovered as any)[key];
      }
    }

    let className = getClassNameFromStyleSystemProps(
      { ...props, ...pseudoSelectorProps },
      baseClassName
    );

    if (props.className) {
      className += ' ' + props.className;
    }

    const elementProps: any = { className };

    // Only passthrough non style props to the base element
    for (let key in props) {
      if (key in propClassNameMap) continue;
      if (key === 'className') continue;
      elementProps[key] = (props as any)[key];
    }

    if (props.focused) {
      elementProps.onFocus = (e: any) => {
        setIsFocused(true);
        if (props.onFocus) props.onFocus(e);
      };

      elementProps.onBlur = (e: any) => {
        setIsFocused(false);
        if (props.onBlur) props.onBlur(e);
      };
    }

    if (props.hovered) {
      elementProps.onMouseEnter = (e: any) => {
        setIsHovered(true);
        if (props.onMouseEnter) props.onMouseEnter(e);
      };

      elementProps.onMouseLeave = (e: any) => {
        setIsHovered(false);
        if (props.onMouseLeave) props.onMouseLeave(e);
      };
    }

    return React.createElement(tag, elementProps, props.children);
  };

  Element.displayName = tag;

  return Element;
}

type StyledComponent<Props> = React.FC<
  Omit<Props, 'className'> & Partial<StyleSystemProps> & { className?: string }
>;

/**
 * Decorates a component with styled props. Your component MUST handle
 * adding classNames. Whereever you put the className prop, that's what
 * will have the tachyons classes
 * @param Component The base component you wish to decorate
 */
export function makeStyledComponent<Props>(
  Component: React.ComponentType<Props & { className?: string }>
): StyledComponent<Props> {
  const StyledComponent: StyledComponent<Props> = props => {
    let className = getClassNameFromStyleSystemProps(props);

    if (props.className) {
      className += ' ' + props.className;
    }

    const componentProps: any = { className };

    // Only passthrough non style props to the base element
    for (let key in props) {
      if (key in propClassNameMap) continue;
      if (key === 'className') continue;
      componentProps[key] = (props as any)[key];
    }

    return React.createElement(Component, componentProps, props.children);
  };

  return StyledComponent;
}

/**
 * Gets the tachyons classname given some style system props
 * @param props style props like marginTop and paddingVertical
 */
export function getClassNameFromStyleSystemProps(
  props: Partial<StyleSystemProps>,
  baseClassName?: string
) {
  const classNames = [];

  if (baseClassName) {
    classNames.push(baseClassName);
  }

  // Translate props like marginVertical=3 to a className like mv3
  for (let key in props) {
    if (isStyleProp(key)) {
      const value = props[key];

      // Check to see if all 4 breakpoitns were specified
      if (!isFullBreakpointTuple(value)) {
        // Even though it's not a full breakpoint specification, it could
        // be a [mobile, notMobile] style breakpoint
        if (isPartialBreakpointTuple(value)) {
          const [mobile, notSmall] = value;
          classNames.push(getClassName({ key, value: mobile, breakpoint: 0 }));
          classNames.push(getClassName({ key, value: notSmall, breakpoint: 1 }));
        } else {
          // In this case, no breakpoints were specified
          classNames.push(getClassName({ key, value, breakpoint: 0 }));
        }
      } else {
        // All 4 breakpoints were specified
        const [mobile, notSmall, medium, desktop] = value;

        classNames.push(getClassName({ key, value: mobile, breakpoint: 0 }));
        classNames.push(getClassName({ key, value: notSmall, breakpoint: 1 }));
        classNames.push(getClassName({ key, value: medium, breakpoint: 2 }));
        classNames.push(getClassName({ key, value: desktop, breakpoint: 3 }));
      }
    }
  }

  return classNames.join(' ');
}
