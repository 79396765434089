/**
 * Export HTML elements here. Each one is usable like:
 * <Div marginTop={4} /> => <div className="mt4" />
 *
 * If you find that your favorite HTML element hasn't been created yet,
 * just add it to this file!
 */
import { makeElement, makeStyledComponent } from './Elements';
import {
  Container as BootstrapContainer,
  Row as BootstrapRow,
  Col as BootstrapCol,
} from 'react-bootstrap';

export { makeElement, makeStyledComponent };

// Structural
export const Div = makeElement('div');
export const Span = makeElement('span');
export const Nav = makeElement('nav');
export const Article = makeElement('article');
export const Details = makeElement('details');
export const Summary = makeElement('summary');
export const Figure = makeElement('figure');
export const Main = makeElement('main');
export const Aside = makeElement('aside');
export const Section = makeElement('section');
export const Header = makeElement('header');
export const Footer = makeElement('footer');
export const Table = makeElement('table');
export const Tbody = makeElement('tbody');
export const Td = makeElement('td');
export const Th = makeElement('th');
export const Thead = makeElement('thead');
export const Tr = makeElement('tr');

// Grid
export const Container = makeStyledComponent(BootstrapContainer);
export const Row = makeStyledComponent(BootstrapRow);
export const Col = makeStyledComponent(BootstrapCol);

// Typographical and Copy
export const P = makeElement('p');
export const A = makeElement('a');
export const H1 = makeElement('h1', 'headline-1');
export const H2 = makeElement('h2', 'headline-2');
export const H3 = makeElement('h3', 'headline-3');
export const H4 = makeElement('h4', 'headline-4');
export const H5 = makeElement('h5', 'headline-5');
export const Img = makeElement('img');
export const Hr = makeElement('hr', 'bt-only');
export const Small = makeElement('small');
export const Strong = makeElement('strong');
export const Code = makeElement('code');
export const Pre = makeElement('pre');
export const Em = makeElement('em');
export const Dl = makeElement('dl');
export const Dd = makeElement('dd');
export const Dt = makeElement('dt');
export const Ul = makeElement('ul');
export const Ol = makeElement('ol');
export const Li = makeElement('li');
export const Sup = makeElement('sup');

// Forms
export const Form = makeElement('form');
export const Input = makeElement('input');
export const Select = makeElement('select');
export const Textarea = makeElement('textarea');
export const Button = makeElement('button');
export const Label = makeElement('label');
